import Image from 'next/image'
import leftMeatAndBones from '../../../../public/assets/discount-banner/left_meat_and_bones.png'
import rightMeatAndBones from '../../../../public/assets/discount-banner/right_meat_and_bones.png'
import { Discount } from '../../../api/queries/useFetchDiscountByCode'
import { Stack } from 'components/Stack'
import { CloseButton, DollarValue, FixedImages, OneLineCopyWrapper } from '../DiscountBanner.styles'
import { DiscountTypes } from '../../../models/Discount'

export const DefaultRedLineBanner = (params: {
  code: any
  data: Discount
  recurringDiscountSum: string
  discountValueOff: string
  setIsHidden: any
  discountType: DiscountTypes
}) => {
  const { code, data, recurringDiscountSum, discountValueOff, setIsHidden, discountType } = params
  const discountTreatQuantity = twoTreatDiscounts.includes(code) ? 2 : 1
  const treatCodeCopy = code.toLowerCase().includes('bison')
    ? `bison treats ($${27.99 * discountTreatQuantity} value)!`
    : code.toLowerCase().includes('pork')
    ? `pork treats ($${23.99 * discountTreatQuantity} value)!`
    : undefined

  const freeCopyQuantity = discountTreatQuantity === 2 ? '2 FREE bags' : 'a FREE bag'
  const freeCopy = treatCodeCopy
    ? `PLUS, ${freeCopyQuantity} of all-natural ${treatCodeCopy}`
    : '+ Free Shipping!'

  const whichBox = code === 'J1CA5C40' ? 'next' : 'first'

  const isTreatsForOneYear = treatsForOneYear.includes(code)

  return (
    <Stack
      justifyContent="space-between"
      alignItems="center"
      css={{ position: 'relative' }}
      gap="0"
    >
      <FixedImages pageSpeed="true">
        <Image src={leftMeatAndBones} alt="meat and bones" loading="eager" />
      </FixedImages>

      <OneLineCopyWrapper
        direction={{ '@initial': 'column', '@bp2': 'wrap' }}
        justifyContent="center"
        css={{ flex: '3', padding: '10px 0px 10px 0px' }}
      >
        {isTreatsForOneYear ? (
          <Stack direction="wrap" justifyContent="center">
            <Stack
              gap="0"
              justifyContent="center"
              direction={{ '@initial': 'column', '@bp3': 'row' }}
            >
              {' '}
              Get FREE Treats for a Year
              <div css={{ fontWeight: '600' }}>&nbsp;+ {discountValueOff} off your first box!</div>
            </Stack>
          </Stack>
        ) : data.subscriptionChargeLimit &&
          data.subscriptionChargeLimit > 1 &&
          recurringDiscountSum ? (
          <Stack direction="wrap" justifyContent="center">
            <Stack direction="wrap" justifyContent="center" gap="0">
              Get&nbsp;
              <DollarValue>{recurringDiscountSum} off </DollarValue>
              <div style={{ fontWeight: 600, display: 'inline-block' }}>&nbsp;{freeCopy}</div>
            </Stack>
            <Stack gap="0" justifyContent="center">
              That&apos;s {discountValueOff} off {data.subscriptionChargeLimit}&nbsp;boxes{' '}
            </Stack>
          </Stack>
        ) : discountType === DiscountTypes.FIXED ? (
          <div>
            Introducing the {discountValueOff} Trial Box + Free Shipping!
            <CloseButton aria-label="Close" onClick={() => setIsHidden(true)}>
              x
            </CloseButton>
          </div>
        ) : data.channel?.toLowerCase() === 'referral' ? (
          <Stack direction="wrap" justifyContent="center">
            <Stack
              gap="0"
              justifyContent="center"
              direction={{ '@initial': 'column', '@bp3': 'row' }}
            >
              Redeem Your First FREE Box + FREE Shipping!
            </Stack>
          </Stack>
        ) : (
          <Stack direction="wrap" justifyContent="center">
            <Stack
              gap="0"
              justifyContent="center"
              direction={{ '@initial': 'column', '@bp3': 'row' }}
            >
              {' '}
              Get {discountValueOff} off your{' '}
              {data?.subscriptionChargeLimit
                ? `first ${data.subscriptionChargeLimit} boxes`
                : `${whichBox} box`}{' '}
              <div css={{ fontWeight: '600' }}>&nbsp;{freeCopy}</div>
            </Stack>
          </Stack>
        )}
      </OneLineCopyWrapper>
      <FixedImages pageSpeed="true">
        <Image src={rightMeatAndBones} alt="meat and bones" loading="eager" />
      </FixedImages>

      <CloseButton aria-label="Close" onClick={() => setIsHidden(true)}>
        x
      </CloseButton>
    </Stack>
  )
}

const twoTreatDiscounts = ['35PORK', '40PORK', 'PORK40']
const treatsForOneYear = ['TREATS730', 'FUSARO801']
