import { GenericObject } from 'types/GenericObject'
import qs from 'query-string'

export const objectToQueryString = (obj: GenericObject) => {
  const newQsValue = qs.stringify(obj, {
    arrayFormat: 'bracket',
    skipEmptyString: true,
    skipNull: true,
  })

  return newQsValue.length ? `?${newQsValue}` : ''
}
