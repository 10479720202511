import { isObject } from 'utils/isObject'

const NAMESPACE = 'wfr'

export const Storage = {
  get: (key: string): any => {
    if (typeof window === 'undefined') return
    const data = localStorage.getItem(`${NAMESPACE}-${key}`)

    if (!data) return null

    try {
      return JSON.parse(data)
    } catch {
      return data
    }
  },

  set: (key: string, value: any): boolean => {
    if (typeof window === 'undefined') return false

    if (!key || value == undefined) {
      return false
    }

    const data = isObject(value) ? JSON.stringify(value) : value

    try {
      localStorage.setItem(`${NAMESPACE}-${key}`, data)
      return true
    } catch {
      // We are swallowing the errors because Some browsers like Mobile Safari allows
      // using locaStorage but with the quota of 0 which leads to an error in the console
      // More info: https://github.com/marcuswestin/store.js/issues/232
      return false
    }
  },

  remove: (key: string): void => {
    if (typeof window === 'undefined') return
    return localStorage.removeItem(`${NAMESPACE}-${key}`)
  },

  has: (key: string): boolean => {
    if (typeof window === 'undefined') return false
    const data = localStorage.getItem(`${NAMESPACE}-${key}`)

    return !!data
  },

  getExact(key: string): any {
    if (typeof window === 'undefined') return
    const data = localStorage.getItem(key)

    if (!data) return null

    try {
      return JSON.parse(data)
    } catch {
      return data
    }
  },
}

export enum StorageKeys {
  ECOMMERCE_CHECKOUT_SETTINGS = 'ECOMMERCE_CHECKOUT_SETTINGS',
  _VID = '_vid',
  UTM_KEYS = 'UTM_KEYS',
  PROMO_CODE = 'PROMO_CODE',
  VERSION_FLAG = 'VERSION_FLAG',
  DAILY_DOSE = 'DAILY_DOSE',
  APPLE_PAY_ELIGIBLE = 'APPLE_PAY_ELIGIBLE',
  IMPACT_RADIUS_CLICK_ID = 'IMPACT_RADIUS_CLICK_ID',
  VWO_EXPERIMENTS = 'VWO_experiments',
}
