import { OrderType } from './Order'

export type ApplyDiscountRequest = {
  code?: string
  clientEmail?: string
  orderType: OrderType
}

export enum DiscountTypes {
  PERCENTAGE = 'PERCENTAGE',
  FIXED = 'FIXED',
  FREE_SHIPPING = 'FREE_SHIPPING',
  DIRECT = 'DIRECT',
}
