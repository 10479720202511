import { useEffect, useState } from 'react'
import { useDiscountCode } from '../../contexts/DiscountContext'
import { CookieKeys, getCookie } from '../../services/Cookies'
import { Storage, StorageKeys } from '../../services/Storage'
import { SessionKeys, SessionStorage } from '../../services/Session'
import { formatCurrencyWithoutDecimals } from '../../utils/currency'
import { DiscountBannerRoot } from './DiscountBanner.styles'
import { BlackFridayBolts } from './DiscountBannerTypes/BlackFridayBolts'
import { DefaultRedLineBanner } from './DiscountBannerTypes/DefaultRedLineBanner'

export const DiscountBanner = ({ pathName }: { pathName: string }) => {
  const [isHidden, setIsHidden] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(true)
  const [discountValueOff, setDiscountValueOff] = useState('')
  const [recurringDiscountSum, setRecurringDiscountSum] = useState('')
  const {
    discountCodeData: data,
    isLoading,
    error,
    refetchDiscountCode: refetch,
  } = useDiscountCode()

  const code = Storage.get(StorageKeys.PROMO_CODE) ?? ''
  const allowedPages =
    pathName.length === 1 ||
    [
      '/meal-plan/step-1',
      '/meal-plan/step-2',
      '/meal-plan/step-3',
      '/meal-plan/step-4',
      '/meal-plan/checkout',
      '/recipes',
      '/recipes/beef',
      '/recipes/lamb',
      '/recipes/chicken',
      '/recipes/turkey',
      '/recipes/duck',
      '/recipes/venison'
    ].some((page) => pathName.includes(page))

  useEffect(() => {
    if (Boolean(SessionStorage().getItem(SessionKeys.BANNER_HIDE))) {
      setIsHidden(true)
    }
    setIsLoggedIn(Boolean(getCookie(CookieKeys.SESSION)))
    if (!data) return

    if (!data.isActive) {
      Storage.set(StorageKeys.PROMO_CODE, 'WAG90')
      refetch()
      return
    }

    const type = data.type
    const amount = data.amount

    setDiscountValueOff(
      type === 'PERCENTAGE' ? `${amount}%` : `${formatCurrencyWithoutDecimals(amount)}`
    )
    setRecurringDiscountSum(
      data.subscriptionChargeLimit && type === 'DIRECT'
        ? `${formatCurrencyWithoutDecimals(amount * data.subscriptionChargeLimit)}`
        : ''
    )
  }, [data, refetch])

  useEffect(() => {
    if (!isHidden) return
    SessionStorage().setItem(SessionKeys.BANNER_HIDE, 'true')
  }, [isHidden])

  if (!discountValueOff || isLoading || !allowedPages || isLoggedIn) return null

  const discountType = boltBannerDiscounts.includes(code) ? 'blackFriday' : 'redLineBanner'

  return (
    <DiscountBannerRoot type={discountType} css={isHidden ? { display: 'none' } : {}}>
      {discountType === 'blackFriday' ? (
        <BlackFridayBolts
          code={code}
          data={data}
          recurringDiscountSum={recurringDiscountSum}
          discountValueOff={discountValueOff}
          setIsHidden={setIsHidden}
          discountType={data.type}
        />
      ) : (
        <DefaultRedLineBanner
          code={code}
          data={data}
          recurringDiscountSum={recurringDiscountSum}
          discountValueOff={discountValueOff}
          setIsHidden={setIsHidden}
          discountType={data.type}
        />
      )}
    </DiscountBannerRoot>
  )
}
DiscountBanner.displayName = 'DiscountBanner'

const boltBannerDiscounts = [
  'BKF120',
  'BKF40',
  'CYBER120',
  'CYBER40',
  'CYBER120',
  'WAG120',
  'RAW120',
]
