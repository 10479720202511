import { Storage, StorageKeys } from '../services/Storage'

export const ENV = {
  isStaging:
    process.env.NEXT_PUBLIC_API_URL &&
    (process.env.NEXT_PUBLIC_API_URL.includes('staging') ||
      process.env.NEXT_PUBLIC_API_URL.includes('local')),
  isBeta: process.env.NEXT_PUBLIC_API_URL && process.env.NEXT_PUBLIC_API_URL.includes('beta'),
  isProd:
    process.env.NEXT_PUBLIC_API_URL &&
    !process.env.NEXT_PUBLIC_API_URL.includes('staging') &&
    !process.env.NEXT_PUBLIC_API_URL.includes('beta'),
  isNotProd:
    process.env.NEXT_PUBLIC_API_URL &&
    (process.env.NEXT_PUBLIC_API_URL.includes('staging') ||
      process.env.NEXT_PUBLIC_API_URL.includes('beta')),
  getVersionFlags: () => Storage.get(StorageKeys.VERSION_FLAG),
  getApplePayEligible: () => Storage.get(StorageKeys.APPLE_PAY_ELIGIBLE),
}
