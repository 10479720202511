import { Stack } from '../../Stack'
import {
  LeftBoltsWrapper,
  LeftBolts,
  LeftMarble,
  CopyWrapper,
  CloseButton,
  RightBoltsWrapper,
  RightBolts,
  RightMarble,
} from '../DiscountBanner.styles'
import Image from 'next/image'
import leftBoltsSource from '../../../../public/assets/discount-banner/left_bolts.png'
import rightBoltsSource from '../../../../public/assets/discount-banner/right_bolts.png'
import leftMarbleBoltsSource from '../../../../public/assets/discount-banner/left_marble_bolts.png'
import rightMarbleBoltsSource from '../../../../public/assets/discount-banner/right_marble_bolts.png'
import { isBefore } from 'date-fns'
import { Discount } from '../../../api/queries/useFetchDiscountByCode'
import { DiscountTypes } from '../../../models/Discount'

export const BlackFridayBolts = (params: {
  code: any
  data: Discount
  recurringDiscountSum: string
  discountValueOff: string
  setIsHidden: any
  discountType: DiscountTypes
}) => {
  const { code, data, recurringDiscountSum, discountValueOff, setIsHidden, discountType } = params
  const defaultBFCode = code.includes('WAG120') || code.includes('RAW120')

  const boltBannerTitle =
    code.includes('BKF') || (defaultBFCode && isBeforeDate('2023-11-27'))
      ? 'BKF_TITLE'
      : 'CYB_TITLE'
  const isEarlyBlackFriday = isBeforeDate('2023-11-24') ? 'BKF_EARLY' : 'BKF'
  const boltBannerType =
    code.includes('BKF') || (defaultBFCode && isBeforeDate('2023-11-27'))
      ? isEarlyBlackFriday
      : 'CYB'

  const boltBannerCopyText = boltBannerCopy[boltBannerType]
  const boltBannerTitleText = boltBannerCopy[boltBannerTitle]

  function isBeforeDate(date: string) {
    const currentDate = new Date()
    const targetDateTime = new Date(date)
    return isBefore(currentDate, targetDateTime)
  }

  return (
    <Stack direction="row" justifyContent={{ '@initial': 'center', '@bp2': 'space-between' }}>
      <LeftBoltsWrapper>
        <LeftBolts className="left-bolts">
          <Image src={leftBoltsSource} alt="left-bolts" />
        </LeftBolts>
        <LeftMarble className="left-marble">
          <Image src={leftMarbleBoltsSource} alt="left-marble" />
        </LeftMarble>
      </LeftBoltsWrapper>
      <CopyWrapper direction="column" gap="1">
        <Stack css={{ fontWeight: 500, fontSize: '18px' }} justifyContent="center">
          {boltBannerTitleText}
        </Stack>
        <div>
          <Stack
            direction={{ '@initial': 'column', '@bp3': 'row' }}
            justifyContent="center"
            gap="1"
          >
            <Stack
              direction={{ '@initial': 'column', '@bp3': 'row' }}
              gap="0"
              justifyContent="center"
              // dangerouslySetInnerHTML={{ __html: boltBannerCopyText }}
            >
              {boltBannerType === 'BKF_EARLY' && (
                <Stack
                  css={{
                    fontSize: '24px',
                    fontWeight: '500',
                    '@bp3': { fontWeight: '500', fontSize: '18px' },
                  }}
                  justifyContent="center"
                >
                  EARLY ACCESS!
                </Stack>
              )}
              <Stack
                css={
                  boltBannerType !== 'BKF_EARLY'
                    ? {
                        fontSize: '20px',
                        fontWeight: '500',
                        '@bp3': { fontWeight: '500', fontSize: '18px' },
                      }
                    : {}
                }
                justifyContent="center"
              >
                &nbsp;{boltBannerCopyText}
              </Stack>
            </Stack>

            {data.subscriptionChargeLimit &&
            data.subscriptionChargeLimit > 1 &&
            recurringDiscountSum ? (
              <Stack
                gap="0"
                justifyContent="center"
                direction={{ '@initial': 'column', '@bp3': 'row' }}
              >
                <Stack justifyContent="center">
                  <div>
                    Get{' '}
                    <div style={{ fontWeight: 500, display: 'inline-block' }}>
                      {recurringDiscountSum} Off
                    </div>{' '}
                    + Free Shipping!
                  </div>
                </Stack>
                <Stack gap="0" justifyContent="center">
                  &nbsp;That&apos;s {discountValueOff} off each of your first{' '}
                  {data.subscriptionChargeLimit}&nbsp;boxes{' '}
                </Stack>
              </Stack>
            ) : discountType === DiscountTypes.FIXED ? (
              <div>
                Introducing The {discountValueOff} Trial Box + Free Shipping on All Orders!
                <CloseButton aria-label="Close" onClick={() => setIsHidden(true)}>
                  x
                </CloseButton>
              </div>
            ) : (
              <Stack
                gap="0"
                justifyContent="center"
                direction={{ '@initial': 'column', '@bp3': 'row' }}
              >
                {' '}
                Get {discountValueOff} off your{' '}
                {data?.subscriptionChargeLimit
                  ? `first ${data.subscriptionChargeLimit} boxes`
                  : 'trial box'}{' '}
                + Free Shipping
              </Stack>
            )}
          </Stack>
          <CloseButton aria-label="Close" onClick={() => setIsHidden(true)}>
            x
          </CloseButton>
        </div>
      </CopyWrapper>
      <RightBoltsWrapper className="right-bolts-wrapper">
        <RightBolts className="right-bolts">
          <Image src={rightBoltsSource} alt="right-bolts" />
        </RightBolts>
        <RightMarble className="right-marble">
          <Image src={rightMarbleBoltsSource} alt="right-marble" />
        </RightMarble>
      </RightBoltsWrapper>
    </Stack>
  )
}

const boltBannerCopy = {
  BKF_EARLY: ` Black Friday Starts NOW.`,
  BKF: 'BLACK FRIDAY IS HERE.',
  CYB: 'CYBER MONDAY IS HERE.',
  BKF_TITLE: 'BEST DEAL OF THE YEAR!',
  CYB_TITLE: 'HOT DIGGITY DOG!',
}
