// DiscountCodeContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useFetchDiscountCode } from '../api/queries/useFetchDiscountByCode'
import { Storage, StorageKeys } from '../services/Storage'
import { formatCurrencyWithoutDecimals } from '../utils/currency'

interface DiscountCodeContextProps {
  discountCodeData: any // Define the type according to your data structure
  isLoading: boolean
  error: any
  refetchDiscountCode: () => void
}

type MyComponentProps = {
  children: React.ReactNode
}

const DiscountCodeContext = createContext<DiscountCodeContextProps | undefined>(undefined)

export const DiscountCodeProvider: React.FC<MyComponentProps> = ({ children }) => {
  const [discountCodeData, setDiscountCodeData] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<any>(null)
  const code = Storage.get(StorageKeys.PROMO_CODE) ?? null
  const { data, status, refetch } = useFetchDiscountCode(code, {
    enabled: !!code,
  })

  const formatForDisplay = (data: any) => {
    const discountType = data.type
    const discountAmount =
      discountType === 'PERCENTAGE'
        ? `${data.amount}%`
        : `${formatCurrencyWithoutDecimals(data.amount)}`

    const discountDuration = data.subscriptionChargeLimit
    const durationTotalAmount =
      discountDuration && formatCurrencyWithoutDecimals(data.amount * discountDuration)
    let displayText = ''

    if (discountType === 'PERCENTAGE') {
      displayText = `Get ${discountAmount} off your first box!`
    } else if (!!discountDuration) {
      displayText = `Get ${durationTotalAmount} off! That's ${discountAmount} off ${discountDuration} boxes`
    } else if (discountType === 'DIRECT') {
      displayText = `Get ${discountAmount} off your first box!`
    } else if (discountType === 'FIXED') {
      displayText = `Introducing the ${discountAmount} Trial Box + Free Shipping!`
    }
    return { ...data, displayText }
  }

  useEffect(() => {
    setIsLoading(status === 'loading')
    if (status === 'error') {
      setError(error)
    } else if (status === 'success') {
      const formattedData = formatForDisplay(data)
      setDiscountCodeData(formattedData)
    }
  }, [data, status, error])

  const refetchDiscountCode = () => {
    refetch()
  }

  return (
    <DiscountCodeContext.Provider
      value={{ discountCodeData, isLoading, error, refetchDiscountCode }}
    >
      {children}
    </DiscountCodeContext.Provider>
  )
}

export const useDiscountCode = () => {
  const context = useContext(DiscountCodeContext)
  if (!context) {
    throw new Error('useDiscountCode must be used within a DiscountCodeProvider')
  }
  return context
}
