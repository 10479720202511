import { GenericObject } from 'types/GenericObject'

export const deepMerge = (target: GenericObject<any>, source: GenericObject<any>) => {
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object)
      Object.assign(source[key], deepMerge(target[key], source[key]))
  }

  Object.assign(target || {}, source)
  return target
}
