import { useRouter as useNextRouter } from 'next/router'

interface TransitionOptions {
  shallow?: boolean
  locale?: string | false
  scroll?: boolean
}

type RouterProps = {
  clearQuery?: boolean
}

export const useRouter = (props: RouterProps = {}) => {
  const nextRouter = useNextRouter()
  const query = typeof window !== 'undefined' && !props.clearQuery ? window.location.search : ''

  return {
    ...nextRouter,
    push: (url: string, as?: string, options?: TransitionOptions) =>
      nextRouter.push(`${url}${query}`, as, options),
    replace: (url: string, as?: string, options?: TransitionOptions) =>
      nextRouter.replace(`${url}${query}`, as, options),
  }
}

export const useLocationSearch = () => {
  return typeof window !== 'undefined' ? window.location.search : undefined
}
