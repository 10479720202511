import { styled } from 'stitches.config'
import { Stack } from '../Stack'

export const DiscountBannerRoot = styled('header', {
  fontSize: '18px',
  lineHeight: '24px',
  padding: '24px 0',
  position: 'relative',
  textAlign: 'center',
  zIndex: '5000000',
  variants: {
    type: {
      primary: {
        fontWeight: '500',
        backgroundColor: '$quaternary-200',
        '> div': {
          color: '$secondary-500',
          margin: '0 0',
        },
      },
      blackFriday: {
        padding: '0 0',
        backgroundColor: '$primary-500',
        '> div': {
          color: '$secondary-500',
          margin: '0 0px',
        },
      },
      redLineBanner: {
        padding: ' 0',
        backgroundColor: '$quaternary-400',
        '> div': {
          color: '$secondary-500',
          margin: '0 0px',
        },
      },
    },
  },
  defaultVariants: {
    type: 'primary',
  },
})

export const CloseButton = styled('button', {
  backgroundColor: 'transparent',
  border: 'none',
  color: 'inherit',
  cursor: 'pointer',
  float: 'right',
  height: '32px',
  width: '32px',
  position: 'absolute',
  top: '0',
  right: '0',
  zIndex: 1,
  font: 'inherit',
  fontSize: '24px',
})

export const LeftBoltsWrapper = styled('div', {
  position: 'absolute',
  left: '0px',
  '@media(min-width: 768px)': {
    position: 'initial',
    display: 'block',
  },
  '@media (min-width: 1500px)': {
    position: 'relative',
    display: 'flex',
    flex: '8 1 0%',
  },
})

export const RightBoltsWrapper = styled('div', {
  position: 'absolute',
  right: '0px',
  '@media(min-width: 768px)': {
    position: 'initial',
    display: 'block',
  },
  '@media (min-width: 1500px)': {
    position: 'relative',
    display: 'flex',
    flex: '8 1 0%',
  },
})

export const LeftMarble = styled('div', {
  display: 'none',
  '@media (min-width: 1500px)': {
    position: 'absolute  !important',
    margin: '0px !important',
    left: '0px !important',
    bottom: '-6px',
    display: 'block',
  },
})

export const RightMarble = styled('div', {
  display: 'none',
  '@media (min-width: 1500px)': {
    position: 'absolute !important',
    margin: '0px !important',
    right: '0px !important',
    bottom: '-6px',
    display: 'block',
  },
})

export const LeftBolts = styled('div', {
  display: 'none',
  '@media (max-width: 1499px)': {
    margin: '0px !important',
    display: 'block',
  },
  '@media (max-width: 1199px)': {
    position: 'relative !important',
    top: '24px',
  },
})

export const RightBolts = styled('div', {
  display: 'none',
  '@media (max-width: 1499px)': {
    margin: '0px !important',
    display: 'block',
  },
  '@media (max-width: 1199px)': {
    position: 'relative !important',
    top: '24px',
  },
})

export const CopyWrapper = styled(Stack, {
  padding: '24px 50px',
  '@media (min-width: 1500px)': {
    padding: '10px 50px',
  },

  '@media(min-width: 768px)': { flex: '1 0 auto' },
})

export const OneLineCopyWrapper = styled(Stack, {
  flexDirection: 'column',
})

export const FixedImages = styled(Stack, {
  display: 'none',
  '@bp2': {
    display: 'flex',
    flex: 1,
  },
  '@bp3': {
    flex: 'none',
  },
  variants: {
    pageSpeed: {
      true: {
        '@bppsd': {
          display: 'none !important',
        },
        '@bppsm': {
          display: 'none !important',
        },
      },
    },
  },
})

export const BlockImage = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  '@bp2': {
    display: 'none',
  },
  variants: {
    pageSpeed: {
      true: {
        '@bppsd': {
          display: 'none !important',
        },
        '@bppsm': {
          display: 'none !important',
        },
      },
    },
  },
})

export const RedLine = styled('div', {
  backgroundColor: '$primary-500',
  height: '2px',
  width: '100px',
  margin: '0 auto !important',
  '@bp2': {
    display: 'none',
  },
})

export const DollarValue = styled('div', {
  fontWeight: '$medium',
  display: 'inline-block',
  '@bp2': {
    borderBottom: '2px $primary-500 solid',
  },
})

export const NoBoldToBold = styled(Stack, {
  fontWeight: '$regular',
  fontSize: '$small',
  '@bp2': {
    fontWeight: '$medium',
    fontSize: '$3',
  },
})

export const IntroCopy = styled(Stack, {
  display: 'flex',
  flexDirection: 'column !important',
  justifyContent: 'center !important',
  '@media(min-width: 900px)': {
    justifyContent: 'center !important',
    direction: 'wrap !important',
    flexWrap: 'wrap !important',
    flexDirection: 'row !important',
  },
})
