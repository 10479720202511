import { useRouter } from 'hooks/useRouter'
import React, { useEffect, useState } from 'react'

type ShoppingCartSidebarContextProps = {
  showSidebar: boolean
  setShowSidebar: (value: boolean) => void
}
const ShoppingCartSidebarContext = React.createContext<ShoppingCartSidebarContextProps>({
  showSidebar: false,
  setShowSidebar: () => {},
})

export const ShoppingCartSidebarProvider = ({ children }: { children: React.ReactNode }) => {
  const [showSidebar, setShowSidebar] = useState<boolean>(false)

  // Close sidebar when navigating to avoid flicker
  const router = useRouter()
  useEffect(() => {
    router.events.on('routeChangeComplete', () => {
      setShowSidebar(false)
    })
  }, [router])

  return (
    <ShoppingCartSidebarContext.Provider value={{ showSidebar, setShowSidebar }}>
      {children}
    </ShoppingCartSidebarContext.Provider>
  )
}

export function useShoppingCartSidebarProvider() {
  const context = React.useContext(ShoppingCartSidebarContext)

  if (typeof context === 'undefined') {
    throw new Error(
      'useShoppingCartSidebarProvider must be used within a ShoppingCartSidebarProvider'
    )
  }

  return context
}
