import { ToastProps } from 'components/Toast/Toast'
import React, { useState } from 'react'

type Toast = ToastProps

const ToastContext = React.createContext<Toast[] | undefined>(undefined)
const ToastUpdaterContext = React.createContext<
  { addToast: (toast: Toast) => void; removeToast: (toastId: string) => void } | undefined
>(undefined)

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [toasts, setToasts] = useState<Toast[]>([])

  const removeToast = (toastId: string) => {
    const filteredToasts = toasts.filter((toast) => toast.id !== toastId)
    setToasts(filteredToasts)
  }

  const addToast = (toast: Toast) => {
    const isRepeated = toasts.some((existingToast) => existingToast.id === toast.id)
    if (isRepeated) return

    setToasts([...toasts, toast])
  }

  return (
    <ToastContext.Provider value={toasts}>
      <ToastUpdaterContext.Provider value={{ addToast, removeToast }}>
        {children}
      </ToastUpdaterContext.Provider>
    </ToastContext.Provider>
  )
}

export function useToastProvider() {
  const context = React.useContext(ToastContext)

  if (typeof context === 'undefined') {
    throw new Error('useToastProvider must be used within a ToastProvider')
  }

  return context
}

export function useToastUpdater() {
  const toastUpdater = React.useContext(ToastUpdaterContext)

  if (typeof toastUpdater === 'undefined') {
    throw new Error('useToastUpdater must be used within a ToastProvider')
  }

  return toastUpdater
}

type ToastContextType = {
  toasts: Toast[]
  addToast: (toast: Toast) => void
  removeToast: (toastId: string) => void
}
export const useToast = (): ToastContextType => ({
  toasts: useToastProvider(),
  ...useToastUpdater(),
})
