import { CookieKeys, getCookie } from 'services/Cookies'
import { getPayloadFromJWT } from 'utils/jwt'

export const getClientId = () => {
  if (typeof window === 'undefined') return
  let clientId = undefined

  const accessToken = getCookie(CookieKeys.SESSION)
  clientId = accessToken ? getPayloadFromJWT(accessToken).sub : getCookie(CookieKeys.GUEST_ID)

  return clientId ?? undefined
}
