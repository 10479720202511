import { CookieKeys, getCookie } from 'services/Cookies'
import { GenericObject } from 'types/GenericObject'
import { deepMerge } from './deepMerge'
import { objectToQueryString } from './objectToQueryString'

export type FetchOptions = {
  headers?: Headers
  data?: any
  responseType?: 'text' | 'json' | 'stream' | 'blob' | 'arrayBuffer' | 'formData'
  params?: GenericObject
}

type Headers = {
  [name: string]: string
}

type Response<T> = {
  status: number
  statusText: string
  config: FetchOptions
  data: T
  headers: Headers
  redirect: boolean
  url: string
  type: ResponseType
  body: ReadableStream<Uint8Array> | null
  bodyUsed: boolean
}

export function createApiClient(baseURL: string) {
  function client<T>(endpoint: string, config: FetchOptions = {}, method: string): Promise<T> {
    const response = { config } as Response<T>
    const customHeaders: Headers = {}
    let url = `${baseURL}${endpoint}`
    let data = config.data

    if (typeof window !== 'undefined' && !config.headers?.Authorization) {
      const jwt = getCookie(CookieKeys.SESSION)
      if (jwt) {
        customHeaders['Authorization'] = `Bearer ${jwt}`
      }
    }

    if (data && typeof data === 'object' && typeof data.append !== 'function') {
      data = JSON.stringify(data)
      customHeaders['content-type'] = 'application/json'
    }

    if (config.params) {
      url += objectToQueryString(config.params)
    }

    return fetch(url, {
      method,
      body: data as any,
      headers: deepMerge(config.headers || {}, customHeaders),
    }).then((res: any) => {
      for (const i in res) {
        if (typeof res[i] != 'function') (response as any)[i] = res[i]
      }

      if (config.responseType === 'stream') {
        response.data = res.body as any
        return response.data
      }

      return res[config.responseType || 'text']()
        .then((data: any) => {
          response.data = data
          // its okay if this fails: response.data will be the unparsed value:
          response.data = JSON.parse(data)
        })
        .catch(Object)
        .then(() => {
          return res.ok ? response.data : Promise.reject(response.data)
        })
    })
  }

  client.get = <T extends {}>(url: string, config?: FetchOptions) => client<T>(url, config, 'GET')
  client.delete = <T extends {}>(url: string, config?: FetchOptions) =>
    client<T>(url, config, 'DELETE')
  client.post = <T extends {}>(url: string, config?: FetchOptions) => client<T>(url, config, 'POST')
  client.put = <T extends {}>(url: string, config?: FetchOptions) => client<T>(url, config, 'PUT')
  client.patch = <T extends {}>(url: string, config?: FetchOptions) =>
    client<T>(url, config, 'PATCH')

  client.CancelToken = typeof AbortController == 'function' ? AbortController : Object

  return client
}
