import { useQuery, UseQueryOptions } from 'react-query'
import { ApiClient } from 'services/ApiClient'
import { Api } from 'types/Api'
import { DiscountTypes } from '../../models/Discount'

export type Discount = {
  discountId: string
  type: DiscountTypes
  amount: number
  isRecurrent: boolean
  code: string
  isActive: boolean
  subscriptionChargeLimit?: number
  channel: string
  tags: string[]
}

export namespace FetchDiscountCode {
  export type Response = Discount
  export type Error = Api.Error
  export type Options = UseQueryOptions<Response, Error>
}

const createKey = (discountCode: string) => ['discount-code', discountCode]

const queryFetcher = (discountCode: string) => () => {
  return ApiClient.get<FetchDiscountCode.Response>(`/v1/discounts/code/${discountCode}`)
}

export const useFetchDiscountCode = (discountCode: string, options?: FetchDiscountCode.Options) => {
  return useQuery<FetchDiscountCode.Response, FetchDiscountCode.Error>(
    createKey(discountCode),
    queryFetcher(discountCode),
    options
  )
}
