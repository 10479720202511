export const formatCurrency = (amount: number) => {
  return `$${formatPrice(amount)}`
}

export const formatPrice = (amount: number) => {
  return (amount / 100).toFixed(2)
}

export const formatCurrencyWithoutDecimals = (amount: number) =>
  formatCurrency(amount).replace('.00', '')

export const formatPriceToNearestWholeNumber = (amount: number) => {
  return `$${Math.round(amount / 100)}`
}

export const calculateSafePrice = (price: number | undefined): string => {
  if (typeof price === 'number') {
    return formatCurrencyWithoutDecimals(price)
  }
  return 'N/A'
}