type StorageType = 'session'
type UseStorageReturnValue = {
  getItem: (key: string, type?: StorageType) => string
  setItem: (key: string, value: string, type?: StorageType) => boolean
  removeItem: (key: string, type?: StorageType) => void
}

export const SessionStorage = (): UseStorageReturnValue => {
  const isBrowser: boolean = ((): boolean => typeof window !== 'undefined')()

  const getItem = (key: string): string => {
    return isBrowser ? window['sessionStorage'][key] : ''
  }

  const setItem = (key: string, value: string): boolean => {
    if (isBrowser) {
      window['sessionStorage'].setItem(key, value)
      return true
    }

    return false
  }

  const removeItem = (key: string): void => {
    window['sessionStorage'].removeItem(key)
  }

  return {
    getItem,
    setItem,
    removeItem,
  }
}

export enum SessionKeys {
  VISIT_ID = 'VISIT_ID',
  GA = '_ga',
  UTM_SOURCE = 'UTM_SOURCE',
  RECENT_ORDER_ID = 'RECENT_ORDER_ID',
  BANNER_HIDE = 'BANNER_HIDE',
}
