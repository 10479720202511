export const setCookie = (key: CookieKeys, value: string) => {
  document.cookie = `${key}=${value};path=/`
}

export const getCookie = (key: CookieKeys) => {
  return document.cookie
    .split('; ')
    .find((cookie) => cookie.startsWith(key))
    ?.replace(`${key}=`, '')
}

export const strictGetCookie = (key: CookieKeys) => {
  return document.cookie
    .split('; ')
    .find((cookie) => cookie?.split('=')[0] === key)
    ?.replace(`${key}=`, '')
}

export const removeCookie = (key: CookieKeys) => {
  document.cookie = `${key}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

export enum CookieKeys {
  SESSION = 'WFR_SESSION',
  GUEST_ID = 'WFR_GUEST_SESSION_ID',
  SHOPPING_CART_ID = 'WFR_SHOPPING_CART_ID',
  ONBOARDING_DONE = 'ONBOARDING_DONE',
  FBP = '_fbp',
  FBC = '_fbc',
  PROMO_CODE = 'PROMO_CODE',
  GA = '_ga',
  RECIPE_SELECTOR = 'RECIPE_SELECTOR',
}
